import IndustrialAreaStore from './industrialAreaStore'
import InfrastructureStore from './infrastructureStore'
import InvestmentPotentialStore from './investmentPotentialStore'
import SuccessStoryStore from './successStoryStore'
import IndustrialAreaDetailStore from './industrialAreaDetailStore'
import InfrastructureDetailStore from './infrastructureDetailStore'
import SuccessStoryDetailStore from './successStoryDetailStore'
import InvestmentPotentialDetailStore from './investmentPotentialDetailStore'
import NewsStore from './newsStore'
import NewsDetailStore from './newsDetailStore'
import InvestmentRealizationStore from './investmentRealizationStore'
import LinkStore from './LinkStore'

class RootStore {
	industrialAreaStore = IndustrialAreaStore
	infrastructureStore = InfrastructureStore
	investmentPotentialStore = InvestmentPotentialStore
	successStoryStore = SuccessStoryStore
	industrialAreaDetailStore = IndustrialAreaDetailStore
	infrastructureDetailStore = InfrastructureDetailStore
	successStoryDetailStore = SuccessStoryDetailStore
	investmentPotentialDetailStore = InvestmentPotentialDetailStore
	newsStore = NewsStore
	newsDetailStore = NewsDetailStore
	investmentRealizationStore = InvestmentRealizationStore
	linkStore = LinkStore
}

const rootStore = new RootStore()

export const industrialAreaStore = rootStore.industrialAreaStore
export const infrastructureStore = rootStore.infrastructureStore
export const investmentPotentialStore = rootStore.investmentPotentialStore
export const successStoryStore = rootStore.successStoryStore
export const industrialAreaDetailStore = rootStore.industrialAreaDetailStore
export const infrastructureDetailStore = rootStore.infrastructureDetailStore
export const successStoryDetailStore = rootStore.successStoryDetailStore
export const investmentPotentialDetailStore = rootStore.investmentPotentialDetailStore
export const newsStore = rootStore.newsStore
export const newsDetailStore = rootStore.newsDetailStore
export const investmentRealizationStore = rootStore.investmentRealizationStore
export const linkStore = rootStore.linkStore
export default rootStore
