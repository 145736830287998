import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { industrialAreaStore, infrastructureStore } from '../../../stores/_rootStore'
import Card from '../../Card'
import { useNavigate } from 'react-router-dom'

function FacilityInfrastructureSection({ category }) {
	const { t } = useTranslation()
	const [searchTerm, setSearchTerm] = useState('')
	const navigate = useNavigate()

	useEffect(() => {
		const fetchData = async () => {
			try {
				if (category === 'industrial-area-invest' && industrialAreaStore.data.length === 0) {
					await industrialAreaStore.fetchData()
				}
				if (category === 'infrastructure' && infrastructureStore.data.length === 0) {
					await infrastructureStore.fetchData()
				}
			} catch (error) {
				console.error(error)
			}
		}
		fetchData()
		return () => {
			if (category === 'industrial-area-invest') industrialAreaStore.resetState()
			if (category === 'infrastructure') infrastructureStore.resetState()
		}
		// eslint-disable-next-line
	}, [])

	return (
		<div>
			<h3 className='font-semibold text-xl uppercase border-b-4 border-gray-300 pb-2 mb-5'>{t(category)}</h3>
			<div className='flex md:justify-end'>
				<div className='relative'>
					<input
						type='text'
						className='border border-gray-300 py-2 pl-2 pr-7 w-full focus:outline-none focus:border-gold'
						placeholder={t('search')}
						onChange={(e) => setSearchTerm(e.target.value)}
						onKeyDown={(e) => {
							if (e.key === 'Enter') {
								if (category === 'industrial-area-invest') {
									industrialAreaStore.search(searchTerm)
								}
								if (category === 'infrastructure') {
									infrastructureStore.search(searchTerm)
								}
							}
						}}
					/>
					<i className='fa fa-search absolute right-2 top-3 text-gray-300'></i>
				</div>
			</div>
			<div className='grid grid-cols-2 md:grid-cols-3 gap-4 py-5'>
				{category === 'industrial-area-invest' &&
					(industrialAreaStore.isLoading ? (
						<p>Loading...</p>
					) : industrialAreaStore.data.length > 0 ? (
						industrialAreaStore.data.map((post) => (
							<Card
								key={post.id}
								imgSrc={`${process.env.REACT_APP_IMAGE_URL}${post.post_meta.filter((meta) => meta.meta_key === 'banner')[0].meta_value}`}
								label={post.post_title}
								onClick={() => {
									navigate(`/investment/industrial-area/${post.post_name}`)
								}}
							/>
						))
					) : (
						<p>{t('no-data')}</p>
					))}
				{category === 'infrastructure' &&
					(infrastructureStore.isLoading ? (
						<p>Loading...</p>
					) : infrastructureStore.data.length > 0 ? (
						infrastructureStore.data.map((post) => (
							<Card
								key={post.id}
								imgSrc={`${process.env.REACT_APP_IMAGE_URL}${post.post_meta.filter((meta) => meta.meta_key === 'banner')[0].meta_value}`}
								label={post.post_title}
								onClick={() => {
									navigate(`/investment/infrastructure/${post.post_name}`)
								}}
							/>
						))
					) : (
						<p>{t('no-data')}</p>
					))}
			</div>
			<div className='flex justify-center'>
				{category === 'industrial-area-invest' && industrialAreaStore.hasNext && (
					<button className='bg-gold text-white py-2 px-4 rounded-md' onClick={industrialAreaStore.nextPage}>
						{t('view-more')}
					</button>
				)}
				{category === 'infrastructure' && infrastructureStore.hasNext && (
					<button className='bg-gold text-white py-2 px-4 rounded-md' onClick={infrastructureStore.nextPage}>
						{t('view-more')}
					</button>
				)}
			</div>
		</div>
	)
}

export default observer(FacilityInfrastructureSection)
