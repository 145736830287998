import React from 'react'
import { useTranslation } from 'react-i18next'

function IncentiveSection() {
	const { t } = useTranslation()
	return (
		<div>
			<h3 className='font-semibold text-xl uppercase border-b-4 border-gray-300 pb-2 mb-2'>{t('incentive')}</h3>
			<div className='flex flex-col gap-2'>
				<img src='/assets/images/investment/insentif-1.png' alt='Insentive Procedure 1' />
				<img src='/assets/images/investment/insentif-2.png' alt='Insentive Procedure 2' />
				<img src='/assets/images/investment/insentif-3.png' alt='Insentive Procedure 3' />
				<img src='/assets/images/investment/insentif-4.png' alt='Insentive Procedure 4' />
				<img src='/assets/images/investment/insentif-5.png' alt='Insentive Procedure 5' />
			</div>
		</div>
	)
}

export default IncentiveSection
