import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Autoplay } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/swiper-bundle.css'

export default function AtAGlance() {
	const { t } = useTranslation()
	const swiperRef = useRef(null)
	const buttonNextRef = useRef(null)
	const buttonPrevRef = useRef(null)
	const handleVideoPlay = () => {
		if (swiperRef.current && swiperRef.current.autoplay) {
			swiperRef.current.autoplay.stop()
			buttonNextRef.current.classList.add('swiper-button-disabled')
			buttonPrevRef.current.classList.add('swiper-button-disabled')
		}
	}

	const handleVideoPauseOrEnded = () => {
		if (swiperRef.current && swiperRef.current.autoplay) {
			swiperRef.current.autoplay.start()
			buttonNextRef.current.classList.remove('swiper-button-disabled')
			buttonPrevRef.current.classList.remove('swiper-button-disabled')
		}
	}
	return (
		<div className='mb-16'>
			<div className='px-8 mt-16'>
				<h2 className='font-bold text-slate-700'>{t('road_to_cjibf')}</h2>
				<div className='border-b-2 border-primary-light mt-4'></div>
			</div>
			<div className='mx-8 lg:mx-16 mt-8'>
				<Swiper
					modules={[Navigation, Autoplay]}
					navigation={{
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev'
					}}
					loop={true}
					autoplay={{
						delay: 5000,
						disableOnInteraction: false
					}}
					style={{ width: '100%', height: '70vh' }}
					onSwiper={(swiper) => {
						swiperRef.current = swiper
						const videos = document.querySelectorAll('video')
						videos.forEach((video) => {
							video.addEventListener('play', handleVideoPlay)
							video.addEventListener('pause', handleVideoPauseOrEnded)
							video.addEventListener('ended', handleVideoPauseOrEnded)
						})
					}}
				>
					<SwiperSlide>
						<video className='object-cover object-center w-full h-full rounded-lg' preload='none' poster='/assets/images/thumbnail-cjibf.png' controls>
							<source src='/assets/videos/cjibf.mp4' type='video/mp4' />
						</video>
					</SwiperSlide>

					<SwiperSlide>
						<video className='object-cover object-center w-full h-full rounded-lg' preload='none' poster='/assets/images/thumbnail-seremoni-cjibf.png' controls>
							<source src='/assets/videos/seremoni_cjibf.mp4' type='video/mp4' />
						</video>
					</SwiperSlide>

					<div className='swiper-button-prev text-white' ref={buttonPrevRef}></div>
					<div className='swiper-button-next text-white' ref={buttonNextRef}></div>
				</Swiper>
			</div>
		</div>
	)
}
